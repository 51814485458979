<template>
  <div class="leaflet-page">
  </div>
</template>
<script>
import { huaxiaMobileSubmitApi } from '@/api/base1';

export default {
  name: 'HuaxiaBank',
  components: {},
  data() {
    return {
    };
  },
  mounted() {
    // 华夏银行前职页
    // document.title = '华夏银行宣传页';
    this.jumpHuaxia();
  },
  computed: {
    submitNum() {
      return this.$route.query.submitNum;
    },
  },
  methods: {
    async jumpHuaxia() {
      let res;
      const { submitNum } = this;
      try {
        res = await huaxiaMobileSubmitApi({ submitNum });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === '1') {
        let linkUrl = res.data;
        window.open(linkUrl, '_self');
      } else {
        this.$toast(res?.desc || '网络异常');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.leaflet-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>
